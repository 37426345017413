import { useJsApiLoader } from "@react-google-maps/api";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { advgList, storageFacility } from "../../helpers/MenuList";
import { ReviewsProps } from "../../helpers/types/getTypes";
import Button from "../commons/button/Button";
import Image from "../commons/image/Image";
import { PopoverOne, PopoverTwo } from "../commons/popover/Popover";
import { AnimeContainer } from "../plugins/Anime";
import AutoCompleteMap from "../plugins/map/AutoCompleteMap";
import { LOADER } from "../plugins/map/helper";

type Props = {
  mode: string;
  reviews: ReviewsProps[];
};

const Dashboard = ({ mode, reviews }: Props) => {
  const { isLoaded, loadError } = useJsApiLoader(LOADER as any);
  const router = useRouter();
  const [searchShow, setSearchShow] = useState("");

  const changeLocation = (place_id: string, desc: any) => {
    const geocode = new window.google.maps.Geocoder();
    geocode
      .geocode({
        placeId: place_id,
      })
      .then(async ({ results }) => {
        const pos = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };

        router.push(
          `${
            process.env.NEXT_PUBLIC_BUYER_URL
          }/b/sign-up?lat=${results[0].geometry.location.lat()}&lng=${results[0].geometry.location.lng()}&radius=50&plan=monthly&address=${
            results[0].formatted_address
          }`
        );
      });
  };

  // GET CURRENT LOCATION
  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      async (position: GeolocationPosition) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        router.push(
          `${process.env.NEXT_PUBLIC_BUYER_URL}/b/sign-up?lat=${position.coords.latitude}&lng=${position.coords.longitude}&radius=50&plan=monthly`
        );
      },
      () => {
        console.log("error");
      }
    );
  };

  return (
    <>
      {/* BANNER */}
      <div className="container mx-auto">
        <div
          id="anime"
          className="min-h-[35.5rem] md:min-h-[43.75rem] px-4.5 md:px-16 bg-new-header bg-center bg-cover bg-no-repeat relative flex flex-col items-center md:justify-center"
        >
          {/* bg-absolute */}
          <div
            className="absolute left-0 w-full h-full md:block hidden z-0"
            style={{
              background:
                "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
            }}
          />
          <div
            className="absolute left-0 w-full h-full z-0 hidden"
            style={{
              background:
                "linear-gradient(to bottom,rgba(255, 255, 255, 1),rgba(255, 255, 255, 0),rgba(255, 255, 255, 1))",
            }}
          />
          <div className="relative z-20 flex flex-col items-center mt-[6.563rem] md:mt-0">
            <div className="max-w-[21.188rem] md:max-w-[33.438rem] text-title3 leading-[2.125rem] md:text-title1 md:leading-[3.625rem] font-bold text-neutral-900 text-center z-0">
              The Easy Way To Find{" "}
              <span className="text-gradient-7">Storage Unit Auctions</span>
            </div>
            <div className="mt-4.5 md:mt-7 max-w-[21.188rem] md:max-w-[37rem] text-neutral-700 text-small-normal leading-6 md:!text-lg text-center z-10">
              Join over 300,000 storage auction hunters who rely on us to find
              upcoming storage unit auctions happening around them
            </div>
            <div className="mt-6 md:mt-8 w-full md:max-w-[33.438rem]">
              <div className="relative">
                <div className="flex flex-row justify-between w-full ">
                  {/* AUTOCOMPLETE */}
                  {searchShow && (
                    <div
                      className={`fixed inset-0 z-10 transition-opacity bg-transparent opacity-50 ${
                        searchShow === "close" && "hidden"
                      }`}
                      onClick={() => {
                        setSearchShow("close");
                      }}
                    />
                  )}

                  <div className="w-full absolute left-0 z-10">
                    {isLoaded && (
                      <AutoCompleteMap
                        label="Search location..."
                        changeLocation={changeLocation}
                        currentLocation={currentLocation}
                        searchShow={(e) => setSearchShow(e)}
                        inputSearchOnClick={(e) => {
                          router.push(
                            `${process.env.NEXT_PUBLIC_BUYER_URL}/b/sign-up`
                          );
                        }}
                        show={searchShow}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="anime-s2" className="md:static relative w-full h-full">
            {mode && <AnimeContainer mode={mode} />}
          </div>
        </div>
      </div>
      {/*  ADVANTAGE */}
      <div className="container mx-auto">
        <AnimationOnScroll
          animateIn="animate__fadeIn"
          className="mt-12 lg:mt-8 mx-auto lg:w-[60rem] xl:w-[68.125rem] gradient-main md:rounded-[4rem] lg:p-16 px-4.5 py-6"
          initiallyVisible
          animateOnce
        >
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOnce
            initiallyVisible
          >
            <div className="text-center text-title3 leading-[2.125rem] md:text-title1 md:leading-[3.625rem] font-bold text-neutral-900">
              The Leading{" "}
              <span className="text-gradient-7">Storage Unit Auction</span>{" "}
              Search Engine
            </div>
            <div className="flex justify-center">
              <div className="text-center text-small-tight leading-6 md:text-title3 md:leading-[2.125rem] text-neutral-900 mt-3 font-normal md:w-full w-[21.188rem]">
                All the tools you need to hunt for storage unit auctions
              </div>
            </div>
          </AnimationOnScroll>

          <div className="md:px-10 grid grid-cols-1 md:grid-cols-2 md:gap-x-5 gap-y-5 md:gap-y-12 md:mt-12 mt-9">
            {mode &&
              advgList.map((e, key: number) => (
                <div key={key} className="flex flex-col">
                  <AnimationOnScroll
                    animateIn="animate__zoomIn"
                    className={`rounded-[0.875rem] p-3.5 w-fit ${e.className}`}
                    animateOnce
                    initiallyVisible={mode === "mobile" && key === 0}
                  >
                    <e.Icon size="34" />
                  </AnimationOnScroll>

                  <AnimationOnScroll
                    animateIn="animate__slideInUp"
                    animateOnce
                    initiallyVisible={mode === "mobile" && key === 0}
                  >
                    <div className="text-large-normal md:text-title3 font-bold text-neutral-900 mt-4.5">
                      {e.title}
                    </div>
                    <div className="text-neutral-700 text-small-normal md:text-large-normal md:mt-3.5 mt-2">
                      {e.subtitle}
                    </div>
                  </AnimationOnScroll>
                </div>
              ))}
          </div>
        </AnimationOnScroll>
      </div>
      {/* MAP */}
      <div className="container mx-auto">
        <div className="mt-12 md:mt-[7rem] xl:mt-[9.75rem] bg-map-desktop-2 bg-contain md:bg-auto bg-center bg-no-repeat h-[30rem] md:min-h-[38rem] relative md:w-[50rem] xl:w-[59.75rem] md:mx-auto mx-4.5">
          <div className="pulsating-circle top-1/2 left-[2rem] md:left-[20rem] md:top-[4.25rem] md:w-6 md:h-6 w-3 h-3" />
          <div className="pulsating-circle top-[11.5rem] left-[5rem] md:left-[3.1rem] md:top-[13rem] md:w-6 md:h-6 w-3 h-3" />
          <div className="pulsating-circle right-1/2 top-[12rem] md:right-[1.5rem] md:top-[10.5rem] md:w-6 md:h-6 w-3 h-3" />
          <div className="pulsating-circle right-3 top-1/2 md:right-[15.5rem] md:top-[8rem] md:w-6 md:h-6 w-3 h-3" />
          <div className="pulsating-circle right-1/3 bottom-[12rem] md:right-[25rem] md:bottom-1/2 md:w-6 md:h-6 w-3 h-3" />

          <AnimationOnScroll
            animateIn="animate__slideInUp"
            animateOnce
            className="absolute bottom-0 md:bottom-[10rem] left-0 md:left-[1rem] lg:left-[-4rem] xl:left-[-6rem] shadow-lv5 bg-white p-4.5 md:w-[21.875rem] w-[16.125rem] rounded-xl"
          >
            <PopoverOne />
          </AnimationOnScroll>

          <AnimationOnScroll
            animateIn="animate__slideInUp"
            className="absolute bottom-3/4 right-0 md:bottom-1/3 md:right-[2.5rem] lg:right-[-4rem] xl:right-[-5rem] shadow-lv4 bg-white p-3.5 md:w-[20.625rem] rounded-xl w-[15.688rem]"
            animateOnce
          >
            <PopoverTwo />
          </AnimationOnScroll>
        </div>
      </div>
      {/* HOW TO WORK */}
      <div className="container mx-auto">
        <div className="mt-12 md:mt-[8.375rem]">
          <div className="container mx-auto">
            <div className="text-center font-bold text-neutral-900 md:!text-title1 text-title3 leading-9">
              How Does It <span className="md:text-gradient-7">Work?</span>
            </div>
            <div className="mt-3 text-neutral-900 text-center text-title3 leading-9 md:block hidden lg:px-0 px-3">
              Save time by hunting for storage auctions happening around you
              from one place
            </div>
            <div className="mt-3 text-neutral-700 text-center text-small-normal leading-6 md:hidden px-4.5">
              Save time by hunting for storage auctions happening around you
              from one place
            </div>
          </div>

          {/* STEP 1 */}
          <div className="mt-[2.125rem] md:mt-[5.25rem] grid grid-cols-1 md:grid-cols-2 md:gap-x-[3.25rem] gap-y-3 md:gap-y-0">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              className="min-h-[19rem] md:min-h-[20rem] xl:min-h-[27.125rem] mr-4.5 md:mr-0 relative"
              animateOnce
            >
              <div className="absolute w-full h-[17.375rem]  gradient-second rounded-r-[2.125rem] z-0 before:absolute before:w-[40vw] before:h-full before:left-[-40vw] before:gradient-second"></div>
              {/* <div className="relative pl-4.5 pt-[1.375rem] pb-[2.313rem] md:pt-[3.313rem] md:pl-[3.938rem] md:pr-[2.688rem]"> */}
              <div className="relative pl-4.5 pt-[1.375rem] pb-[0] md:pt-[0] md:pl-[0] md:pr-[0] h-full">
                <Image
                  src="/images/step/step-1-main.png"
                  alt="Step 1"
                  className="absolute w-full h-auto "
                />
                <Image
                  src="/images/step/step-1-second.png"
                  alt="Step 1"
                  className="absolute w-[15rem] md:w-[15rem] lg:w-[18rem] xl:w-[27.75rem] h-auto right-0 top-20 md:right-[2rem] md:top-[3rem] lg:right-[4rem] lg:top-[4rem] xl:right-[4rem] xl:top-[5.8rem]"
                />
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__slideInUp"
              className="flex flex-col justify-center items-start space-y-3 md:space-y-4.5 max-w-[27.75rem] md:pr-2 px-4.5 md:mt-0 mt-2"
              animateOnce
            >
              <div className="font-bold text-small-tight md:text-large-none text-warning-500">
                STEP 1
              </div>
              <div className="font-bold text-neutral-900 md:text-4xl md:leading-[2.723rem] text-2xl leading-[2.125rem]">
                Choose Your Locations
              </div>
              <div className="text-neutral-700 md:text-neutral-900 md:text-title3 md:leading-9 text-small-normal leading-6">
                You can search for upcoming auctions near you, or in other parts
                of the country. You can pick multiple locations, or subscribe to
                receive alerts about all auctions.
              </div>
            </AnimationOnScroll>
          </div>

          {/* STEP 2 */}
          <div className="mt-7 md:mt-[5.25rem] grid grid-cols-1 md:grid-cols-2 md:gap-x-[3.25rem] gap-y-3 md:gap-y-0">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              className="min-h-[19rem] md:hidden ml-4.5 md:ml-0 relative"
              animateOnce
            >
              <div className="absolute w-full h-[17.375rem] gradient-second rounded-l-[2.125rem] z-0 before:absolute before:md:w-[40vw] before:h-full before:right-[-40vw] before:gradient-second"></div>
              {/* <div className="relative pr-4.5 pt-4.5 pb-3.5 md:pt-[3.313rem] md:pl-[3.938rem] md:pr-[2.688rem]"> */}
              <div className="relative pr-4.5 pt-4.5 pb-0 md:pt-[0] md:pl-[0] md:pr-[0] h-full">
                <Image
                  src="/images/step/step-2-main.png"
                  alt="Step 2"
                  className="absolute w-full h-auto "
                />
                <Image
                  src="/images/step/step-2-second.png"
                  alt="Step 2"
                  className="absolute w-[15rem] h-auto right-[4rem] top-[5rem]"
                />
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__slideInUp"
              className="w-full flex flex-row items-center justify-end md:pl-2 px-4.5 md:mt-0 mt-2"
              animateOnce
            >
              <div className="flex flex-col justify-center md:items-end space-y-3 md:space-y-4.5 max-w-[27.75rem]">
                <div className="font-bold text-small-tight md:text-large-none text-warning-500">
                  STEP 2
                </div>
                <div className="font-bold text-neutral-900 md:text-4xl md:leading-[2.723rem] text-2xl leading-[2.125rem]">
                  Find Auctions
                </div>
                <div className="text-neutral-700 md:text-neutral-900 md:text-title3 md:leading-9 text-small-normal leading-6 md:text-right">
                  You can save time by looking for auctions on one central
                  dashboard. You will get notifications delivered to you by
                  email or text message so you can stay ahead of the game.
                </div>
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__fadeIn"
              className="min-h-[20rem] xl:min-h-[27.125rem] md:block hidden relative"
              animateOnce
            >
              <div className="absolute w-full h-[17.375rem] gradient-second rounded-l-[2.125rem] z-0 before:absolute before:w-[40vw] before:h-full before:right-[-40vw] before:gradient-second"></div>
              {/* <div className="relative pt-[3.313rem] pl-[3.938rem] pr-[2.688rem] min-h-[27.125rem]"> */}
              <div className="relative pt-[0] pl-[0] pr-[0] h-full">
                <Image
                  src="/images/step/step-2-main.png"
                  alt="Step 2"
                  className="absolute w-full h-auto "
                />
                <Image
                  src="/images/step/step-2-second.png"
                  alt="Step 2"
                  className="absolute w-[15rem] lg:w-[18rem] xl:w-[27.75rem] h-auto right-[3rem] top-[3rem] lg:right-[5rem] lg:top-[4rem] xl:right-[8rem] xl:top-[6.5rem]"
                />
              </div>
            </AnimationOnScroll>
          </div>

          {/* STEP 3 */}
          <div className="mt-7 md:mt-[5.25rem] grid grid-cols-1 md:grid-cols-2 md:gap-x-[3.25rem] gap-y-3 md:gap-y-0">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              className="min-h-[19rem] md:min-h-[20rem] xl:min-h-[27.125rem] mr-4.5 md:mr-0 relative"
              animateOnce
            >
              <div className="absolute w-full h-[17.375rem] gradient-second rounded-r-[2.125rem] z-0 before:absolute before:w-[40vw] before:h-full before:left-[-40vw] before:gradient-second"></div>
              {/* <div className="relative pl-4.5 pt-[1.375rem] pb-[2.313rem] md:pt-[3.313rem] md:pl-[3.938rem] md:pr-[2.688rem]"> */}
              <div className="relative pl-4.5 pt-[1.375rem] pb-[0] md:pt-[0] md:pl-[0] md:pr-[0] h-full">
                <Image
                  src="/images/step/step-3-main.png"
                  alt="Step 3"
                  className="absolute w-full h-auto "
                />
                <Image
                  src="/images/step/step-3-second.png"
                  alt="Step 3"
                  className="absolute w-[15rem] lg:w-[18rem] xl:w-[25.125rem] h-auto right-4 top-[4.5rem] md:right-[1rem] md:top-[3rem] lg:right-[4rem] lg:top-[4rem] xl:right-[5rem] xl:top-[7rem]"
                />
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__slideInUp"
              className="flex flex-col justify-center items-start space-y-3 md:space-y-4.5 max-w-[27.75rem] md:pr-2 px-4.5 md:mt-0 mt-2"
              animateOnce
            >
              <div className="font-bold text-small-tight md:text-large-none text-warning-500 w-full">
                STEP 3
              </div>
              <div className="font-bold text-neutral-900 md:text-4xl md:leading-[2.723rem] text-2xl leading-[2.125rem]">
                Participate In Auctions
              </div>
              <div className="text-neutral-700 md:text-neutral-900 md:text-title3 md:leading-9 text-small-normal leading-6">
                You can attend auctions in person or bid for auctions online on
                many different auction platforms.
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
      {/* READY HUNTING AUCTIONS */}
      <div className="container mx-auto">
        <AnimationOnScroll
          animateIn="animate__fadeIn"
          className="mt-[3.375rem] md:mt-[5rem] xl:mt-[7.75rem] md:mx-[0] xl:mx-[5.5rem] md:w-auto px-4.5 lg:px-[5.438rem] pt-9 md:pt-[4.25rem]"
          animateOnce
        >
          <AnimationOnScroll
            animateIn="animate__slideInUp"
            className="relative rounded-t-[2.125rem] md:rounded-t-[4rem] py-6 px-3 md:py-16 md:px-8 gradient-main flex flex-col items-center"
            animateOnce
          >
            <AnimationOnScroll
              animateIn="animate__slideInRight"
              className="absolute lg:right-[-4.5rem] lg:top-[-4rem] top-[-2.5rem] right-[-1rem]"
              animateOnce
            >
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                <Image
                  src="/images/box.svg"
                  alt="Box"
                  className="lg:w-[10.5rem] lg:h-[10.5rem] w-[60px] h-[60px]"
                />
              </AnimationOnScroll>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__slideInLeft"
              className="absolute lg:left-[-5rem] lg:bottom-[3rem] left-[-2rem] bottom-[-1rem]"
              animateOnce
            >
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                <Image
                  src="/images/bid.svg"
                  alt="Box"
                  className="lg:w-[10.5rem] lg:h-[10.5rem] w-[60px] h-[60px]"
                />
              </AnimationOnScroll>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__slideInUp"
              className="text-[3rem] leading-[4.5rem] font-bold text-neutral-900 w-full md:w-[39.313rem] text-center hidden md:block"
              animateOnce
            >
              Ready To Start Hunting For{" "}
              <span className="text-gradient-7">Storage Unit Auctions?</span>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slideInUp"
              className="text-title3 leading-9 font-bold text-neutral-900 w-[19.688rem] text-center md:hidden"
              animateOnce
            >
              Start Hunt For{" "}
              <span className="text-gradient-7">Storage Unit Auctions</span>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slideInUp"
              className="mt-3.5 md:mt-12 w-full md:w-auto lg:w-[45.25rem] text-neutral-700 md:text-title3 md:leading-[3rem] text-small-normal leading-6 text-center md:font-light"
              animateOnce
            >
              More than 300,000 people save over 15 hours every week by using
              Storage Unit Auction List to find storage unit auctions happening
              near them.
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slideInUp"
              className="mt-[2.375rem] md:mt-12 w-auto md:w-[13.625rem]"
              animateOnce
            >
              <Link href={`${process.env.NEXT_PUBLIC_BUYER_URL}/b/sign-up`}>
                <a target="_blank">
                  <Button
                    size="lg"
                    className="!rounded-full w-full"
                    onClick={() => {}}
                  >
                    Search for Auctions
                  </Button>
                </a>
              </Link>
            </AnimationOnScroll>
          </AnimationOnScroll>
        </AnimationOnScroll>
      </div>
      {/* FACILITIES */}
      <div className="container mx-auto">
        <div className="mt-12 md:mt-[7.75rem] mx-4.5 md:mx-auto flex flex-col md:items-center">
          <div className="text-warning-500 font-medium md:font-bold md:text-large-none text-center text-small-tight">
            Find Auctions From
          </div>
          <div className="mt-3.5 text-neutral-900 font-bold text-center md:text-title2 md:leading-[3rem] text-title3 leading-9">
            Leading <span className="font-extrabold">Storage Facilities</span>
          </div>
          <div className="mt-6 md:mt-[2.875rem] overflow-x-auto sidebar flex flex-row items-center space-x-10 md:space-x-[3.75rem]">
            {storageFacility.map((e, key: number) => (
              <AnimationOnScroll
                animateIn="animate__fadeIn"
                key={key}
                className="min-w-fit grayscale hover:grayscale-0 hover-anime group relative"
                animateOnce
              >
                <div className="absolute bg-white/50 w-full h-full left-0 group-hover:bg-transparent hover-anime" />
                <Image src={e.link} alt={e.alt} className={e.className} />
              </AnimationOnScroll>
            ))}
          </div>
        </div>
      </div>
      {/* REVIEWS */}
      <div className="container mx-auto">
        <AnimationOnScroll
          animateIn="animate__slideInUp"
          className="relative w-full md:w-auto lg:mx-16"
          animateOnce
        >
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            className="absolute h-full w-full px-4.5 md:px-0 md:hidden"
            animateOnce
          >
            <div className="bg-gradient-to-b from-[#FFFBEA] to-[#fff] rounded-[2.125rem] w-full h-full" />
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            className="mt-12 md:mt-[7.75rem] md:bg-gradient-to-b md:from-[#FFFBEA] md:to-[#fff] md:rounded-[4rem] flex flex-col md:px-[1.688rem] md:pb-16 pb-[1.875rem] relative z-10"
            animateOnce
          >
            <div className="text-center text-warning-500 font-medium md:font-bold text-small-tight md:text-large-none uppercase mt-7 md:mt-[2.375rem] ">
              Reviews
            </div>
            <div className="text-center font-bold text-neutral-900 md:text-primary-900 text-title3 leading-9 md:text-[2.5rem] md:leading-[3.75rem] mt-3.5 md:mt-6 px-9 md:px-0">
              What Storage Unit Buyers Are Saying
            </div>

            <div className="mt-6 md:mt-10 md:flex-wrap md:justify-center md:items-start md:gap-[1rem] xl:gap-[2rem] w-full overflow-x-auto sidebar flex md:space-x-0 space-x-4.5 h-auto md:px-0 px-10 md:pb-10">
              {reviews.map((e, key: number) => (
                <AnimationOnScroll
                  animateIn="animate__zoomIn"
                  key={key}
                  className="min-w-[18.063rem] md:basis-1/3 xl:basis-2/12"
                  animateOnce
                >
                  <div className="relative flex flex-col space-y-3 w-full h-auto bg-white rounded-3xl p-4 shadow-[0px_12px_46px_rgba(96,97,112,0.06)]">
                    <div className="flex flex-row space-x-3">
                      <div className="min-w-[4rem] md:min-w-[3rem] xl:min-w-[4rem]">
                        <Image
                          src={`${e.attributes.photo?.data?.attributes.url}`}
                          alt={
                            e.attributes.photo?.data?.attributes
                              .alternativeText || e.attributes.name
                          }
                          className="w-16 h-16 md:w-11 md:h-11 xl:w-16 xl:h-16 object-cover rounded-lg"
                        />
                      </div>
                      <div className="flex flex-col justify-center space-y-1.5 md:space-y-1 xl:space-y-1.5">
                        <div className="font-bold text-neutral-900 text-title3 md:text-small-normal xl:text-title3">
                          {e.attributes.name}
                        </div>
                        <div className="text-neutral-600 text-regular-normal md:text-xs xl:text-regular-normal">
                          {e.attributes.title}
                        </div>
                      </div>
                    </div>
                    <div
                      className="text-neutral-900 text-regular-normal leading-7 md:!text-small-normal xl:text-regular-normal xl:leading-7 break-words
                  text-left"
                    >
                      {e.attributes.description}
                    </div>
                  </div>
                </AnimationOnScroll>
              ))}
            </div>
          </AnimationOnScroll>
        </AnimationOnScroll>
      </div>
    </>
  );
};

export default Dashboard;
