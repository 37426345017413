import { motion } from "framer-motion";
import { ReactNode, useState } from "react";
import {
  ArrowDownBoldIcon,
  BuildingBoldIcon,
  CalendarBulkIcon,
  LocationBoldIcon,
  MapIcon,
  ProfileBulkIcon,
  RecordCircleIcon,
  TickCircle,
} from "../commons/icon/SvgIcon";
import Image from "../commons/image/Image";

const ORIGSPEEDX = 10;
const ORIGSPEEDY = 10;

const Anime = ({
  parentId,
  children,
  svgSize,
  originX = 50,
  originY = 50,
}: {
  children?: ReactNode;
  svgSize?: number;
  parentId: string;
  originX?: number;
  originY?: number;
}) => {
  const [x, setX] = useState(0);
  const [speedX, setSpeedX] = useState(ORIGSPEEDX);
  const [y, setY] = useState(0);
  const [speedY, setSpeedY] = useState(ORIGSPEEDY);
  return (
    <>
      <motion.div
        animate={{ x: x, y: y }}
        transition={{
          ease: "linear",
          duration: 2.5,
        }}
        onAnimationComplete={() => {
          const elem = document.getElementById(parentId);
          if (elem) {
            setX(Math.random() * originX);
            setY(Math.random() * originY);
          }
        }}
      >
        {children}
      </motion.div>
    </>
  );
};

export const AnimeContainer = ({ mode }: { mode: string }) => (
  <>
    {/* ANIME LEFT */}
    <div className="absolute md:left-[0] md:top-[5rem] lg:left-[5rem] lg:top-[5rem] z-0 md:block hidden">
      <Anime parentId={mode === "desktop" ? "anime" : "anime-s2"}>
        <div className="bg-white rounded-full shadow-lv4 p-4.5 text-secondary-500 ">
          <BuildingBoldIcon size="34" />
        </div>
      </Anime>
    </div>
    <div className="absolute md:left-[10rem] md:top-[13rem] z-0 md:block hidden">
      <div className="bg-success-500 rounded-full w-4 h-4" />
    </div>
    <div className="absolute md:left-[-1rem] md:top-[22rem] lg:left-[3rem] lg:top-[16rem] xl:left-[12rem] xl:top-[16rem] md:z-0 z-10 left-[1.938rem] top-[4rem]">
      <Anime
        parentId={mode === "desktop" ? "anime" : "anime-s2"}
        originX={20}
        originY={20}
      >
        <div className="bg-white rounded-full shadow-lv4 p-3.5 md:p-6 text-success-500 ">
          <TickCircle size={mode === "desktop" ? "48" : "24"} />
        </div>
      </Anime>
    </div>

    <div className="absolute md:left-[0] md:top-3/4 lg:left-[5rem] xl:left-[11rem] lg:top-2/3 md:z-0 z-10 left-[5.188rem] top-[10rem]">
      <Anime
        parentId={mode === "desktop" ? "anime" : "anime-s2"}
        originX={mode === "desktop" ? 50 : 30}
        originY={mode === "desktop" ? 50 : 30}
      >
        <div className="bg-white rounded-full shadow-lv4 p-3 md:p-4 text-warning-500">
          <MapIcon size={mode === "desktop" ? "28" : "18"} />
        </div>
      </Anime>
    </div>

    <div className="absolute md:left-[25rem] md:bottom-[6rem] z-0 md:block hidden">
      <div className="bg-success-500 rounded-full w-4 h-4" />
    </div>

    {/* ANIME RIGHT */}
    <div className="absolute md:right-[6rem] md:top-[3rem] xl:right-[6rem] xl:top-[7rem] z-0 md:block hidden">
      <Anime parentId={mode === "desktop" ? "anime" : "anime-s2"}>
        <div className="bg-white rounded-full shadow-lv4 p-4 text-purple-500">
          <LocationBoldIcon />
        </div>
      </Anime>
    </div>

    <div className="absolute md:right-[12rem] md:top-[15rem] md:z-0 z-10 left-[11.25rem] top-[4rem] md:hidden">
      <div className="bg-purple-500 rounded-full w-4 h-4" />
    </div>

    <div className="absolute md:right-[12rem] md:top-[15rem] z-0 md:block hidden">
      <div className="bg-primary-500 rounded-full w-5 h-5" />
    </div>

    <div className="absolute md:right-[1rem] md:top-[10rem] lg:right-[6rem] lg:top-[13rem] xl:right-[13rem] xl:top-[18rem] md:z-0 z-10 right-[1.938rem] top-[3rem]">
      <Anime parentId={mode === "desktop" ? "anime" : "anime-s2"}>
        <div className="bg-white rounded-full shadow-lv4 p-2 md:p-6 text-danger-500">
          <CalendarBulkIcon size={mode === "desktop" ? "48" : "20"} />
        </div>
      </Anime>
    </div>

    <div className="absolute md:right-[2rem] md:top-2/3 lg:right-[5rem] lg:top-1/2 md:z-0 z-10 right-[5.188rem] top-[9rem]">
      <Anime parentId={mode === "desktop" ? "anime" : "anime-s2"}>
        <div className="bg-white rounded-full shadow-lv4 p-2 md:p-5 text-primary-500">
          <ProfileBulkIcon size={mode === "desktop" ? "34" : "14"} />
        </div>
      </Anime>
    </div>

    <div className="absolute md:right-1/4 md:bottom-[5rem] z-0 md:block hidden">
      <div className="bg-warning-500 rounded-full w-5 h-5" />
    </div>
  </>
);

export const AnimeContainerFacility = () => {
  return (
    <>
      <div className="absolute left-[10rem] top-[16rem] z-0">
        <div className="bg-info-500 rounded-full w-4 h-4" />
      </div>

      <div className="absolute left-[-1rem] top-[26rem] lg:left-[1rem] lg:top-[25rem] xl:left-[6rem] xl:top-[18rem] z-0">
        <div className="bg-purple-500 w-[7.875rem] h-[7.875rem] rounded-[0.875rem]" />
      </div>

      <div className="absolute left-[0rem] top-[27rem] lg:left-[2rem] lg:top-[26rem] xl:left-[7rem] xl:top-[19.5rem] z-10">
        <Anime parentId="anime" originX={20} originY={20}>
          <div className="bg-white shadow-lv5 w-[11.75rem] p-3 rounded-[0.875rem] flex flex-col">
            <Image
              src="/images/popover/img-1.png"
              alt="Popover 1"
              className="w-full h-auto "
            />
            <div className="text-small-normal font-medium text-neutral-900 mt-2">
              Central Valley Self Mini Storage Electronic...
            </div>
            <div className="text-tiny-normal text-neutral-600 mt-1">
              Fresno, CA, 1441 N Clovis Ave, 93727
            </div>
            <div className="flex flex-col space-y-1.5 mt-1">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-1">
                  <RecordCircleIcon size="12" className="text-primary-500" />
                  <div className="text-neutral-900 text-[0.625rem] leading-3">
                    Upcoming Auctions
                  </div>
                </div>
                <div className="text-neutral-700 text-[0.625rem] leading-3">
                  100x
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-1">
                  <RecordCircleIcon size="12" className="text-neutral-900" />
                  <div className="text-neutral-900 text-[0.625rem] leading-3">
                    Ended Auctions
                  </div>
                </div>
                <div className="text-neutral-700 text-[0.625rem] leading-3">
                  90x
                </div>
              </div>
            </div>
          </div>
        </Anime>
      </div>

      <div className="absolute left-1/4 bottom-[5rem] z-0">
        <div className="bg-success-500 rounded-full w-4 h-4" />
      </div>

      <div className="absolute right-[2rem] top-[1rem] lg:right-[5rem] lg:top-[3.5rem] xl:right-[12rem] xl:top-[3.5rem] z-0">
        <Anime parentId="anime" originX={20} originY={20}>
          <div className="w-[7.75rem] h-[7.75rem] rounded-full relative bg-[url('/images/popover/map.png')] bg-center bg-no-repeat">
            <div className="absolute left-0 top-0 text-danger-500">
              <LocationBoldIcon size="34" />
            </div>
          </div>
        </Anime>
      </div>
      <div className="absolute right-[6.5rem] top-[17rem] z-0">
        <div className="bg-primary-500 w-5 h-5 rounded-full" />
      </div>

      <div className="absolute right-[3rem] bottom-[8rem] xl:right-[3rem] xl:bottom-1/4 z-0">
        <div className="bg-warning-500 w-[7.875rem] h-[7.875rem] rounded-full" />
      </div>

      <div className="absolute right-[18rem] bottom-[5rem] z-0">
        <div className="bg-warning-500 w-5 h-5 rounded-full" />
      </div>

      <div className="absolute right-[5rem] top-[26rem] xl:right-[5rem] xl:top-1/2 z-0">
        <Anime parentId="anime" originX={20} originY={20}>
          <div className="bg-white shadow-lv3 w-[9.875rem] p-3 rounded-xl flex flex-row justify-between items-center space-x-2">
            <div className="text-regular-none text-neutral-500">
              Auction Type
            </div>
            <div className="text-neutral-900">
              <ArrowDownBoldIcon />
            </div>
          </div>
          <div className="mt-2 bg-white  shadow-lv5 w-[9.875rem] p-3.5 rounded-xl flex flex-col space-y-2">
            <div className="flex flex-row items-center justify-between space-x-2">
              <div className="text-small-normal text-green-500">Online</div>
              <div className="text-green-500">
                <TickCircle />
              </div>
            </div>
            <div className="text-small-normal text-neutral-500">In Person</div>
          </div>
        </Anime>
      </div>
    </>
  );
};

export default Anime;
